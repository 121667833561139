* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  font-family: "Rubik", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
}

/* General reusable contents */
.heading-primary,
.heading-secondary {
  font-weight: 700;
  color: #333;
  letter-spacing: -0.5px;
}

.heading-primary {
  font-size: 5rem;
  line-height: 5.05rem;
  margin-bottom: 3.2rem;
}

.btn,
.btn:link,
.btn:visited {
  display: inline-block;
  font-size: 2rem;
  color: #fff;
  padding: 1.6rem 3.2rem;
  text-decoration: none;
  border-radius: 9px;
  font-weight: 600;
  transition: all 0.3s;
}

.btn--full:link,
.btn--full:visited {
  background-color: #e60000;
  /* margin-right: 1rem; */
}
.btn--full:hover,
.btn--full:active {
  background-color: #cc0000;
  /* box-shadow: 0px 0px 2px 0.5px rgba(80, 80, 80, 0.288); */
}

.btn--outline:link,
.btn--outline:visited {
  background-color: white;
  color: #555;
}
.btn--outline:hover,
.btn--outline:active {
  background-color: #ffffff;
  color: #000000;
  box-shadow: inset 0 0 0 3px rgb(27, 27, 27);
}

/* Container */
.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}
/* Grids */
.grid {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 9.6rem;
  margin-bottom: 9.6rem;
}

.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}

.grid--2-cols {
  grid-template-columns: 1fr 1fr;
}

.grid--3-cols {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid--4-cols {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid--center-v {
  align-items: center;
}
/* Headings */
.subheading {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  color: #cc0000;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

/* HELPER CLASSES */

.margin-right-sm {
  margin-right: 1.6rem !important;
}
.margin-bottom-md {
  margin-bottom: 4.8rem !important;
}

.center-text {
  text-align: center;
}

/* List Styles */
.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.list-item {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.list-icon {
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: #e67e22;
  margin: 0;
  padding: 0;
}

/* For forms */
.btn--form {
  background-color: #ffffff;
  color: #45260a;
  align-self: end;
  padding: 1.2rem;
}

.btn--form:hover {
  background-color: #ffffff;
  color: #000000;
}
