* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  color: #555;
  font-family: Rubik, sans-serif;
  font-weight: 400;
  line-height: 1;
}

.heading-primary, .heading-secondary {
  color: #333;
  letter-spacing: -.5px;
  font-weight: 700;
}

.heading-primary {
  margin-bottom: 3.2rem;
  font-size: 5rem;
  line-height: 5.05rem;
}

.btn, .btn:link, .btn:visited {
  color: #fff;
  border-radius: 9px;
  padding: 1.6rem 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.btn--full:link, .btn--full:visited {
  background-color: #e60000;
}

.btn--full:hover, .btn--full:active {
  background-color: #c00;
}

.btn--outline:link, .btn--outline:visited {
  color: #555;
  background-color: #fff;
}

.btn--outline:hover, .btn--outline:active {
  color: #000;
  background-color: #fff;
  box-shadow: inset 0 0 0 3px #1b1b1b;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  gap: 9.6rem 6.4rem;
  margin-bottom: 9.6rem;
  display: grid;
}

.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}

.grid--2-cols {
  grid-template-columns: 1fr 1fr;
}

.grid--3-cols {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid--4-cols {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid--center-v {
  align-items: center;
}

.subheading {
  color: #c00;
  text-transform: uppercase;
  letter-spacing: .75px;
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: 500;
  display: block;
}

.heading-secondary {
  margin-bottom: 9.6rem;
  font-size: 4.4rem;
  line-height: 1.2;
}

.heading-tertiary {
  margin-bottom: 3.2rem;
  font-size: 3rem;
  line-height: 1.2;
}

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.margin-bottom-md {
  margin-bottom: 4.8rem !important;
}

.center-text {
  text-align: center;
}

.list {
  flex-direction: column;
  gap: 1.6rem;
  list-style: none;
  display: flex;
}

.list-item {
  align-items: center;
  gap: 1.2rem;
  font-size: 1.8rem;
  display: flex;
}

.list-icon {
  color: #e67e22;
  margin: 0;
  padding: 0;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.btn--form {
  color: #45260a;
  background-color: #fff;
  align-self: end;
  padding: 1.2rem;
}

.btn--form:hover {
  color: #000;
  background-color: #fff;
}

/*# sourceMappingURL=index.1639e784.css.map */
